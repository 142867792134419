
import {Helmet} from "react-helmet";

const Services =()=>{
    return(
        <div className='flex flex-col gap-20 bg-[#2c3145]'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>"Services - Comprehensive Digital Solutions from Adey Digital</title>
                <meta name="description" content="Explore the full range of digital services offered by Adey Digital on our Services page. From website design and development to digital marketing, we have the expertise to meet all your online needs." />
                <meta
                    name="keywords"
                    content="Adey Digital, services, website design, development, digital marketing, online, expertise"
                />
            </Helmet>
            <div className='h-[50vh] flex flex-col gap-10 justify-center items-center px-5 sm:px-20 w-full bg-[#262a38]'>
                <h1 className='text-2xl uppercase sm:text-4xl text-white leading-relaxed tracking-widest text-center w-[100%] sm:w-[70%]'>we provide industry leading digital marketing & web development service</h1>
                <p className='text-center text-white font-thin text-lg'>You've built an amazing business. We make sure people will remember it.</p>
                <img src='/line-2.svg'/>
            </div>

            <div className='px-10 sm:px-20 h-auto'>
                <h1 className='uppercase font-thin tracking-wide text-white text-3xl'>what we do<span className='text-[#FFF102] text-7xl'>.</span></h1>
                <p className='font-extralight mt-6 w-full sm:w-[70%] text-xl leading-relaxed sm:text-lg text-white'>You deserve better than a one-size-fits-all option. Our agency's capabilities are focused around one thing – making you the leading front in your industry. Below is a brief list of where we excel.</p>

                <div className='flex flex-col sm:flex-row justify gap-5 justify-between items-center mt-16'>
                    <div className='w-full sm:w-[30%]'>
                        <h3 className='text-white text-2xl'>MARKETING<span className='text-[#FFF102] text-6xl'>.</span></h3>
                        <p className='text-white mt-5 sm:w-[70%]'>
                            Adey Digital offers complete digital marketing solutions to assist companies in engaging and reaching their target market. For the purpose of increasing traffic, boosting conversions, and enhancing online visibility, our team develops tailored plans combining SEO, PPC, social media marketing, and email marketing. By offering specialized solutions that address their particular demands and objectives, we assist businesses in succeeding online.
                        </p>
                    </div>

                    <div className='w-full sm:w-[30%]'>
                        <h3 className='text-white text-2xl'>DESIGN<span className='text-[#FFF102] text-6xl'>.</span></h3>
                        <p className='text-white mt-5 sm:w-[70%]'>Adey Digital provides expert design services to aid companies in creating a distinctive and enduring brand identity. Our design team makes unique logos, branding principles, website designs, and marketing materials that are catered to the particular requirements and objectives of each organization. With the aid of powerful and distinctive design solutions, we assist companies in standing out in their industry and creating an online presence that is unforgettable.
                        </p>
                    </div>
                    <div className='w-full sm:w-[30%]'>
                        <h3 className='text-white text-2xl'>DEVELOPMENT<span className='text-[#FFF102] text-6xl'>.</span></h3>
                        <p className='text-white mt-5 sm:w-[70%]'>Adey Digital offers professional website creation services to aid companies in creating a compelling online presence. Our team builds individual, responsive websites that are tailored to each company's needs and objectives. From conception to launch, we provide end-to-end assistance to make sure each website is a success. Businesses may confidently advance their internet presence with the help of Adey Digital.
                        </p>
                    </div>


                </div>
            </div>

            <div className='bg-[#262a38] w-full py-16 px-10 sm:px-20 h-auto'>
                <div>
                    <p className='text-sm text-white'>How We Work</p>
                    <h3 className='text-2xl text-white mt-6 uppercase w-full sm:w-[70%] leading-relaxed'>Our approach to creating effective websites is simple.
                        Memorable + strategy and planning = The Sweet Spot</h3>

                    <div className='flex flex-col sm:flex-row items-center'>
                        <p className='w-full sm:w-[50%] mt-10 text-white font-thin text-lg'>
                            The first step in creating a memorable brand with adey digital is defining your purpose. Consider who you want to reach out to, what value you bring to the table, and how you will stand out from the competition.
                            <br/>
                            <br/>
                            Once you have established these goals and objectives, you should research which platforms are best suited for your business, such as social media sites, websites, email campaigns etc.
                        </p>
                        <img src='/service.svg' className='w-full mt-5 sm:mt-0 sm:w-[40%]'/>
                    </div>
                </div>
                <img src='/patt.svg' className='w-16 my-5'/>

                <div className='flex flex-col mt-16 gap-8 sm:gap-5 justify-center items-center'>
                    <h1 className='uppercase text-[#FFF102] text-3xl'>that’s not all!</h1>
                    <p className='text-white text-center font-thin text-lg'>A website is an essential part of any digital marketing strategy, as it provides an online presence for your business, sets the tone for customer interactions and serves as a platform to distribute helpful content that can draw customers in. and we can help you make it</p>
                    <button className='bg-[#D9D9D9] px-10 rounded-2xl py-1 font-bold hover:bg-gray-50'>See Our Portfolio</button>
                </div>

            </div>
        </div>
    )
}

export default Services