import React,{useEffect, useState} from "react";
const Faq = ()=>{

    const faqs = [
        {
            index: 1,
            q: 'What services do you offer?',
            a: 'We provide a range of digital marketing services, including website design and development, social media marketing, content creation and optimization, search engine optimization (SEO), pay-per-click campaigns, email marketing, analytics reporting and monitoring, reputation management, and more. \n'
        },
        {
            index: 2,
            q: ' What are the benefits of working with a digital marketing agency? ',
            a: 'Working with an experienced digital marketing agency can help you access a broad range of skills and expertise to drive results for your business. A digital marketing agency can also help you target your message to the right audience at the right time in order to increase conversions and maximise the effectiveness of your campaigns. Furthermore, hiring an agency typically requires less manpower and resources compared to attempting everything yourself or building an internal team. \n'
        },
        {
            index: 3,
            q: 'What type of industries have you worked with in the past? ',
            a: 'We have experience working with a variety of different industries, ranging from healthcare, finance and retail to food & beverage businesses and technology companies. No matter what industry you’re in, we have the experience needed to tailor our campaigns specifically for your business goals.  \n'
        },
        {
            index: 4,
            q: 'What will be required from me if I hire you?',
            a: ' When it comes to projects we typically like to start off by having an initial consultation to learn more about your business needs and goals before recommending a strategy that is tailored specifically for you. After that we would need quite a bit of information from you such as organisational structure/hierarchy data points on goal performance etc., but our team would work closely with you during each step of the process so nothing ever gets too burdensome or overwhelming.  \n'
        },
        {
            index: 5,
            q: 'How long does it take to see results? \n',
            a: 'The amount of time needed depends on the size and scope of each project, but typically we like to begin seeing measurable results within 6-8 weeks after beginning work on any given campaign or strategy—such as increases in traffic or engagement metrics associated with public relations efforts or changes in rankings associated with SEO initiatives—but further improvements often continue occurring over time as modifications are made based on analysis reports generated by our team along the way!\n \n'
        },


    ]
    const [active, setActive] = useState(0);

    const faqClickHandler = (index) => {
        setActive(prev=> prev === index ? 0 : index);
    }

    return(
        <div className='w-full sm:w-[50%]'>
            {
                faqs.map((e,i) =>(
                    <div className='transition-all duration-200 ease-in mt-5  flex flex-col justify-center items-start '>
                        <div className='w-full flex justify-between items-center bg-[#545a75] cursor-pointer px-5 h-auto py-2' onClick={()=>faqClickHandler(e.index)}>
                            <h1 className='text-white text-base sm:text-lg font-normal '>{e.q}</h1>
                            <img src={`${active === e.index ? '/up.svg' : '/down.svg'}`} className='w-6 '/>
                        </div>
                        <p className={`py-4 text-white font-thin ${active === e.index ? 'block' : 'hidden'} shadow-2xl p-3`}>
                            {e.a}
                        </p>
                    </div>
                ))
            }

        </div>
    )
}

export default Faq