import React from 'react'

const Plans =({enabled})=>{
    return(
        <div className='w-full sm:w-[80%] h-auto flex flex-col justify-center items-center'>
            <h1 className='hidden sm:block text-center bg-[#FFF102] w-[30%] py-2 rounded-tr-3xl rounded-tl-3xl font-semibold'>MOST POPULAR</h1>
            <div className='w-full h-full flex-col sm:flex-row gap-10 flex justify-between'>

                <div className='bg-[#2c3145] h-full p-10 rounded-3xl w-full sm:w-[30%] h-full flex flex-col gap-5 items-center'>
                    <h1 className='text-white text-3xl text-center'>Organic</h1>
                    {enabled ? (
                        <h1 className='text-white text-2xl text-center'> <span className='text-[#fff102] font-semibold'>$2,995</span><span className='text-gray-400 text-2xl'>/Mo</span></h1>

                    ):(
                        <h1 className='text-white text-2xl text-center'> <span className='text-[#fff102] font-semibold'>$3,745</span><span className='text-gray-400 text-2xl'>/Mo</span></h1>

                    )}

                    {
                        enabled === true? (

                            <h1 className='text-white text-base font-semibold text-[#9f9a1a]'>Billed monthly</h1>
                        ) : <h1 className='text-transparent'>.</h1>
                    }

                    <ul className='text-white'>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Social Media Manager</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Graphic Designer</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Copywriter</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Content Strategy and Planner with Content Calendar</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Unlimited Content Creation</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Social Media Management (Posting, Engagement, and Management)</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Graphic Design (for Social Media and Website)</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Website SEO Optimization</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Reporting and Analytics</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Email and Phone Support</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Weekly Strategy Calls</p></li>
                    </ul>
                    <button className='bg-[#FFF102] px-5 py-2 rounded-md font-semibold hover:bg-[#ACA40E]'>GET STARTED</button>
                </div>

                <h1 className='sm:hidden text-center bg-[#FFF102] w-full -mb-10 py-2 rounded-tr-3xl rounded-tl-3xl font-semibold'>MOST POPULAR</h1>


                <div className='bg-[#2c3145] p-10 rounded-3xl sm:rounded-none sm:rounded-br-3xl sm:rounded-bl-3xl sm:w-[30%] h-full flex flex-col gap-5 items-center'>
                    <h1 className='text-white text-3xl text-center'>Booster</h1>
                    {
                        enabled ? (
                            <h1 className='text-white text-2xl text-center'> <span className='text-[#fff102] font-semibold'>$3,500</span><span className='text-gray-400 text-2xl'>/Mo</span></h1>

                        ) : (
                            <h1 className='text-white text-2xl text-center'> <span className='text-[#fff102] font-semibold'>$4,375</span><span className='text-gray-400 text-2xl'>/Mo</span></h1>
                        )
                    }

                    {
                        enabled === true? (

                            <h1 className='text-white text-base font-semibold text-[#9f9a1a]'>Billed monthly</h1>
                        ) : <h1 className='text-transparent'>.</h1>
                    }
                    <ul className='text-white '>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>All features of Organic Package</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Ad Specialist </p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Ad Campaign Management (Google, Facebook, Instagram)</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Targeted Ad Campaigns</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Landing Page Design and Optimization</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Conversion Rate Optimization</p></li>
                        {/*<li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Email and Phone Support</p></li>*/}
                        {/*<li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Weekly Strategy Calls</p></li>*/}
                    </ul>
                    <button className='bg-[#FFF102] px-5 py-2 rounded-md font-semibold hover:bg-[#ACA40E]'>GET STARTED</button>

                </div>
                <div className='bg-[#2c3145] p-10 rounded-3xl sm:w-[30%] h-full flex flex-col gap-5 items-center'>
                    <h1 className='text-white text-3xl text-center'>Custom/Enterprise</h1>
                    {
                        enabled ? (
                                <h1 className='text-white text-2xl text-center'> <span className='text-[#fff102] font-semibold'>$4k+</span><span className='text-gray-400 text-2xl'>/Mo</span></h1>

                        ) : (
                            <h1 className='text-white text-2xl text-center'> <span className='text-[#fff102] font-semibold'>$5k+</span><span className='text-gray-400 text-2xl'>/Mo</span></h1>

                        )
                    }

                    {
                        enabled === true? (

                            <h1 className='text-white text-base font-semibold text-[#9f9a1a]'>Billed monthly</h1>
                        ) : <h1 className='text-transparent'>.</h1>
                    }
                    <ul className='text-white'>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>All features of Organic and Inorganic Package</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Customised Team Composition</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Additional Services (Video Production, YouTube Management, Influencer Marketing, etc)</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Advanced Reporting and Analytics</p></li>
                        {/*<li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Dedicated Project Manager</p></li>*/}
                        {/*<li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Email and Phone Support</p></li>*/}
                        {/*<li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Weekly Strategy Calls</p></li>*/}
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>Custom KPI Tracking and Optimization</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>A/B Testing and Multivariate Analysis</p></li>
                        <li className='flex mt-3 justify-start gap-4 leading-relaxed'><img src='/tick.svg'/><p>E-commerce Optimization.</p></li>
                    </ul>
                    <button className='bg-[#FFF102] px-5 py-2 rounded-md font-semibold hover:bg-[#ACA40E]'>GET STARTED</button>

                </div>
            </div>

        </div>
    )
}

export default Plans