import {Link} from 'react-router-dom'
import React,{useState, useEffect} from "react";
import Faq from "../components/faq";
import {Helmet} from "react-helmet";

const About=()=>{




    return(
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>"About Adey Digital - Our Story and Team</title>
                <meta name="description" content="Learn more about the history, mission, and team behind Adey Digital on our About page. Discover our values and approach to providing top-notch digital services." />
                <meta
                    name="keywords"
                    content="Adey Digital, about, history, mission, team, values, digital services"
                />
            </Helmet>
            <div className='h-[40vh] px-10 bg-[#2c3145] flex justify-center items-center flex-col' >
                <h1 className='text-2xl sm:text-5xl text-white leading-relaxed tracking-widest'>ABOUT ADEY DIGITAL</h1>
                {/*<p className='text-white text-lg font-thin mt-3'>Lorem ipsum dolor sit amet consectetur. ipsum dolor sit amet ipsum dolor sit amet Velit tempor dolor a</p>*/}
            </div>
            <div className='bg-[#262a38] h-auto py-20 px-10 sm:px-32 flex flex-col gap-40'>
                <div>
                    <h1 className='text-white text-3xl font-semibold'>Who We Are</h1>
                    <p className='text-white mt-10 tracking-wide text-lg font-thin'>Adey Digital is a digital marketing company that does a lot more than just marketing and development. We are moving with the goal to take your project from start to finish and make sure that our clients are getting the best ROI. With our expirience in all types of industries,  you can be sure we have the right skill set needed for your project. Adey Digital stays up to date with the latest in digital marketing, marketing technology, and design trends and new ways of thinking where we can have an impact on your business that you may not have even realized. We help you understand what's happening in social media arena and make sure your company is knocking out your competitors</p>
                </div>
                <div className='flex flex-col sm:flex-row h-auto sm:h-[50vh] w-full gap-10 h-auto'>
                    <div className='p-5 sm:p-20 h-full w-full sm:w-[40%] rounded-xl bg-[#32374e] flex items-center justify-center'>
                        <img src='/about.svg' className='h-full'/>
                    </div>
                    <div className='w-full sm:w-[60%]'>
                        <h1 className='uppercase text-3xl font-semibold text-white'>why choose us for your digital footprint</h1>
                        <p className='text-white font-thin mt-10 tracking-wide'>Choose Adey Digital to manage your online presence and take advantage of our extensive digital solutions and knowledge. To aid businesses in their online success, our team offers expert website design, development, digital marketing, e-commerce solutions, branding, and graphic design services. Adey Digital is your go-to partner for building a solid and prosperous digital presence because of its focus on providing customized solutions and top-notch customer care.</p>
                        <div className='flex mt-12 w-[100%] justify-around'>
                            <div>
                                <div className='rounded-[50%]'>
                                    <img src='/icons/award.svg'/>
                                </div>
                                <p className='text-white text-base font-thin mt-3'>Super Quality</p>
                            </div>
                            <div>
                                <div className='rounded-[50%]'>
                                    <img src='/icons/teamwork.svg'/>
                                </div>
                                <p className='text-white text-base font-thin mt-3'>Professional team</p>
                            </div>
                            <div>
                                <div className='rounded-[50%]'>
                                    <img src='/icons/like.svg'/>
                                </div>
                                <p className='text-white text-base font-thin mt-3'>unmatched service</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='text-center text-white flex flex-col gap-3 items-center'>
                    <h1 className='text-3xl font-semibold'>Our Team</h1>
                    <p className='font-thin tracking-wide'>Every great project consists of exelent teams </p>
                    <p>The Adey Digital team will help you craft a successful campaign strategy to reach out to new leads through third party sites like Google Adwords. If you're looking for increased sales in 2023 then this is the way to go!</p>
                </div>


                <div className='flex flex-col justify-center items-center'>
                    <h1 className='capitalize text-white text-2xl '>frequently asked questions</h1>
                    <Faq/>
                    {/*<div>*/}
                    {/*    <div>*/}
                    {/*        <div className="mb-10">*/}
                    {/*            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">*/}
                    {/*                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"*/}
                    {/*                     fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*                    <path fill-rule="evenodd"*/}
                    {/*                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"*/}
                    {/*                          clip-rule="evenodd"></path>*/}
                    {/*                </svg>*/}
                    {/*                What do you mean by "Figma assets"?*/}
                    {/*            </h3>*/}
                    {/*            <p className="text-gray-500 dark:text-gray-400">You will have access to download the*/}
                    {/*                full Figma project including all of the pages, the components, responsive pages, and*/}
                    {/*                also the icons, illustrations, and images included in the screens.</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="mb-10">*/}
                    {/*            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">*/}
                    {/*                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"*/}
                    {/*                     fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*                    <path fill-rule="evenodd"*/}
                    {/*                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"*/}
                    {/*                          clip-rule="evenodd"></path>*/}
                    {/*                </svg>*/}
                    {/*                What does "lifetime access" exactly mean?*/}
                    {/*            </h3>*/}
                    {/*            <p className="text-gray-500 dark:text-gray-400">Once you have purchased either the*/}
                    {/*                design, code, or both packages, you will have access to all of the future updates*/}
                    {/*                based on the roadmap, free of charge.</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="mb-10">*/}
                    {/*            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">*/}
                    {/*                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"*/}
                    {/*                     fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*                    <path fill-rule="evenodd"*/}
                    {/*                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"*/}
                    {/*                          clip-rule="evenodd"></path>*/}
                    {/*                </svg>*/}
                    {/*                How does support work?*/}
                    {/*            </h3>*/}
                    {/*            <p className="text-gray-500 dark:text-gray-400">We're aware of the importance of well*/}
                    {/*                qualified support, that is why we decided that support will only be provided by the*/}
                    {/*                authors that actually worked on this project.</p>*/}
                    {/*            <p className="text-gray-500 dark:text-gray-400">Feel free to <a href="#"*/}
                    {/*                                                                            className="font-medium underline text-primary-600 dark:text-primary-500 hover:no-underline"*/}
                    {/*                                                                            target="_blank"*/}
                    {/*                                                                            rel="noreferrer">contact*/}
                    {/*                us</a> and we'll help you out as soon as we can.</p>*/}
                    {/*        </div>*/}
                    {/*        <div className="mb-10">*/}
                    {/*            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white">*/}
                    {/*                <svg className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400"*/}
                    {/*                     fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*                    <path fill-rule="evenodd"*/}
                    {/*                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"*/}
                    {/*                          clip-rule="evenodd"></path>*/}
                    {/*                </svg>*/}
                    {/*                I want to build more than one project. Is that allowed?*/}
                    {/*            </h3>*/}
                    {/*            <p className="text-gray-500 dark:text-gray-400">You can use Windster for an unlimited*/}
                    {/*                amount of projects, whether it's a personal website, a SaaS app, or a website for a*/}
                    {/*                client. As long as you don't build a product that will directly compete with*/}
                    {/*                Windster either as a UI kit, theme, or template, it's fine.</p>*/}
                    {/*            <p className="text-gray-500 dark:text-gray-400">Find out more information by <Link*/}
                    {/*                to="/license"*/}
                    {/*                class="font-medium underline text-primary-600 dark:text-primary-500 hover:no-underline">reading*/}
                    {/*                the license</Link>.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

        </div>
    )
}

export default About