import { useQuery, gql } from "@apollo/client";
import ReactLoading from "react-loading";
import {useNavigate} from "react-router-dom";
const QUERY = gql`
    {
        posts{
            title
          description
            content{
                html
            }
            datePublished
            slug
            author{
                name
                biography
              avatar{
                    url
                }
            }
            coverPhoto{
                url
            }
        }
    }
    
`
const   RecentPost =()=>{
    const navigate = useNavigate();

    const { loading, error, data } = useQuery(QUERY,);

    if (loading) return <div className='flex justify-center items-center'><ReactLoading type='bubbles' color='#DDD104' height={400} width={300} /></div>
    if (error) return <pre className='text-white'>{error.message}</pre>

    return(
        <div className='bg-[#272d47] rounded-3xl px-10 py-5'>

            <h1 className='text-white text-xl border-b-2 pb-3 w-full'>Recent Post</h1>
        {
            data.posts.map((e,i) =>(

        <div onClick={()=>navigate(`/blogdetail/${e.slug}`)} className='cursor-pointer h-auto text-white my-4 flex flex-col items-start gap-6'>
            <div className='flex gap-5 items-center'>
                <img src={e.author.avatar.url} className='w-16 h-16 rounded-[50%]'/>
                <div>
                    <p className='text-white text-base'>{e.datePublished}</p>
                    <p className='text-white text-lg'>{e.title}</p>
                </div>
            </div>
        </div>
            ))
        }
        </div>
    )
}

export default RecentPost