import React, {useEffect, useState} from 'react'
import {Link} from "react-router-dom";
import {BiPhoneCall,BiRightArrowAlt,BiMenuAltLeft} from 'react-icons/bi'
import {AiFillFacebook,AiOutlineInstagram} from 'react-icons/ai'
import {TbBrandTelegram} from 'react-icons/tb'
import {useRoutes} from "react-router-dom";

const HeaderNav = [
    {
        id: 1,
        name: 'Home',
        path: '/'
    },
    {
        id: 2,
        name: 'Services',
        path: '/services'
    },
    {
        id: 3,
        name: 'Pricing',
        path: '/pricing'
    },
    {
        id: 4,
        name: 'Blog',
        path: '/blog'
    },
    {
        id: 5,
        name: 'About',
        path: '/about'
    },

]
const Header = ({scrollPosition}) => {

    const [active, setActive] = useState(false);

    const menuClickHandler = () => {
        setActive(true);
    };
    // const router = useRoutes();

    // useEffect(()=>{
    //     onScrol ? console.log("doownnn") : console.log("upppp")
    // },[onScrol])

    // const page = router.pathName;

    // console.log(page)
    return (
        <div className={` sticky top-0 z-30 h-[12vh] transition-all duration-200 ease-in ${scrollPosition ? 'bg-[#2C3145]' : 'bg-[transparent]'} w-full flex justify-between sm:justify-around items-center px-5 sm:px-20`}>
            <div className='w-[50%] sm:w-[15%]'>
                <a href='/' >
                    <img src='/logo.svg' className='w-32 cursor-pointer'/>

                </a>

            </div>
            <div className='hidden sm:flex w-[40%] justify-around items-center'>
                {HeaderNav.map((i,e)=>(
                    <a href={i.path} key={i.id} className='text-white text-base'>
                        {i.name}
                    </a>
                ))}
            </div>
            <div className='hidden sm:flex w-[25%] justify-around items-center text-white text-base'>
                {/*<div>*/}
                <a href='/contactus' className='flex gap-6'>
                    <BiPhoneCall color='whote' size='1.3rem'  className='cursor-pointer'/>

                    <p className='text-base -ml-4 cursor-pointer'>Contact Us</p>
                </a>
                {/*</div>*/}
                |
                <AiFillFacebook color='whote' size='1.3rem' className='cursor-pointer'/>
                <a href='https://www.instagram.com/adeydigital_official/' target='_blank' >
                     <AiOutlineInstagram color='whote' size='1.3rem' className='cursor-pointer'/>
                </a>
                <TbBrandTelegram color='whote' size='1.3rem' className='cursor-pointer'/>

            </div>

            <div className={`sm:hidden h-[100vh] w-[80%] bg-[#2C3145] absolute top-0 transition-all duration-500 ease-in ${
                active ? "right-0" : "-right-[100%]"
            }`}>
                <div className="w-full h-full flex flex-col items-start justify-start p-10 ">
                    <BiRightArrowAlt
                        onClick={() => setActive(false)}
                        color="white"
                        size="3rem"
                    ></BiRightArrowAlt>
                    {HeaderNav.map((e, i) => (
                        <div key={i} className="my-5 w-full">
                            <a
                                href={e.path}
                                className="block text-lg text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]"
                            >
                                {e.name}
                            </a>
                        </div>
                    ))}
                    <a href='/contactus' className='block w-full mt-2 text-lg text-white text-left pb-3 border-b-[1px] border-[rgba(255, 255, 255, 0.1)]'>Contact Us</a>
                    <div className='flex sm:hidden w-[100%] mt-10 justify-around items-center text-white text-base'>

                        <a href='https://www.facebook.com/profile.php?id=100087645096237'>
                            <AiFillFacebook color='white' size='1.6rem' className='cursor-pointer'/>
                        </a>
                        <a href='https://www.instagram.com/adeydigital_official/'>

                            <AiOutlineInstagram color='white' size='1.6rem' className='cursor-pointer'/>
                        </a>
                        <a href='https://t.me/Eyuealbisrat'>
                        <TbBrandTelegram color='white' size='1.6rem' className='cursor-pointer'/>
                        </a>

                    </div>
                </div>
            </div>


            <div className='sm:hidden w-[20%] ml-auto'>
                <BiMenuAltLeft color='white' size='2.5rem' className='cursor-pointer' onClick={menuClickHandler}/>
            </div>
        </div>
    )
}


export default Header
