import React, { useState } from 'react';
import {BiRightArrowAlt} from "react-icons/bi";

function MailChimpForm() {
    const [email, setEmail] = useState('');
    const [helperText, setHelperText] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email) {
            setHelperText('Email is required');
            return;
        }

        const formData = new FormData();
        formData.append('EMAIL', email);
        console.log(formData)
        fetch('https://adeydigital.us9.list-manage.com/subscribe/post?u=3b10ce45d5cb2ecdb4b1510b6&id=7ac58818f2&f_id=002d1fe1f0', {
            method: 'POST',
            body: formData
        })
            .then(() => {
                setHelperText('Subscribed successfully');
                setEmail('');
            })
            .catch((error) => {
                setHelperText(error.message);
            });
    };

    return (
        <div id="mc_embed_signup" className='w-full sm:w-[30%] rounded-sm p-8  bg-[#3c3f4c]'>
            <form
                action="https://adeydigital.us9.list-manage.com/subscribe/post?u=3b10ce45d5cb2ecdb4b1510b6&amp;id=7ac58818f2&amp;f_id=002d1fe1f0"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
                onSubmit={handleSubmit}
            >
                {/*<div id="mc_embed_signup_scroll">*/}
                    <h1 className='text-white font-semibold text-lg'>Subscribe</h1>
                    {/*<div className="indicates-required">*/}
                    {/*    <span className="asterisk">*</span> indicates required*/}
                    {/*</div>*/}
                    <div className="mc-field-group flex items-center ">
                        {/*<label htmlFor="mce-EMAIL">*/}
                        {/*    Email Address*/}
                        {/*    <span className="asterisk">*</span>*/}
                        {/*</label>*/}
                        <input
                            type="email"
                            value={email}
                            name="EMAIL"
                            className='px-2 sm:px-4 py-3 outline-none rounded-tl-md rounded-bl-md  my-5'
                            placeholder='Email Address'
                            id="mce-EMAIL"
                            required
                            onChange={(event) => setEmail(event.target.value)}
                        />


                        <button type="submit" onClick={handleSubmit}>
                            <div className='py-2 px-2 bg-[#FFF102] rounded-tr-md rounded-br-md cursor-pointer' onClick={handleSubmit}>
                                            <BiRightArrowAlt className='' size='2rem' />

                                        </div>
                        </button>
                    </div>
                    {
                        helperText === ''? (
                            <p className='text-transparent'>.</p>
                        ) : (
                    <span id="mce-EMAIL-HELPERTEXT" className="helper_text text-[#9f9a1a]">{helperText}</span>
                        )
                    }

                    <p className='text-gray-300 font-thin'>Hello, we are Adey. Our goal is to translate the positive effects from revolutionizing how companies engage with their clients & their team.</p>
</form>
</div>
);
}
export default MailChimpForm;