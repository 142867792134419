import BlogCard from "../components/BlogCard";
import RecentPost from "../components/RecentPost";
// import {GraphQLClient, gql} from "graphql-request";
import {useEffect, useState} from "react";
import { useQuery, gql } from "@apollo/client";
import ReactLoading from 'react-loading';
import {Helmet} from "react-helmet";

// const graphcms = new GraphQLClient('https://api-eu-central-1-shared-euc1-02.hygraph.com/v2/clde4zv7a38t201uh8ud010vj/master')

const QUERY = gql`
    {
        posts{
            title
          description
            content{
                html
            }
            datePublished
            slug
            author{
                name
                biography
              avatar{
                    url
                }
            }
            coverPhoto{
                url
            }
        }
    }
`
const Blog =()=>{

    // const [posts, setPosts] = useState([])
    //
    // useEffect(() =>{
    //
    // },[])

    const { data, loading, error } = useQuery(QUERY);

    if (loading) return  <div className='flex justify-center items-center'><ReactLoading type='bubbles' color='#DDD104' height={400} width={300} /></div>
    if (error) return <pre>{error.message}</pre>

    // eslint-disable-next-line react-hooks/rules-of-hooks




    return(
        <div className='flex w-full flex-col sm:flex-row px-5 sm:px-20 gap-5 h-auto justify-around'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blog - Stay Up-to-date with the Latest in Digital with Adey Digital</title>
                <meta name="description" content="Stay informed with the latest news, trends and insights from Adey Digital on our Blog page. Follow our updates and expert advice to stay ahead of industry advancements in the digital world." />
                <meta
                    name="keywords"
                    content="Adey Digital, blog, updates, insights, digital industry, trends, advancements"
                />
            </Helmet>
            <div className='w-full sm:w-[60%]'>
                {
                    data.posts.map((e,i) =>(
                        <div className='flex mb-20 flex-col justify-between w-full gap-20'>
                            <BlogCard title={e.title} authorName={e.author.name} description={e.description} image={e.coverPhoto.url} date={e.datePublished} slug={e.slug} avatar={e.author.avatar.url}/>
                        </div>
                    ))
                }

            </div>
            <div className='w-full sm:w-[35%]'>
                <RecentPost />
            </div>
            {/*<h1 className='text-white text-3xl sm:text-5xl font-semibold'><span className='text-[#fff102]'>Blog</span> Coming Soon</h1>*/}
            {/*<p className='text-gray-500 text-3xl'>Stay tuned</p>*/}
        </div>
    )
}

export default Blog