import Plans from "../components/Plans";
import Features from "../components/features";
import React,{useState} from "react";
import {Helmet} from "react-helmet";

const Pricing =()=>{

    const [enabled, setEnabled] = useState(true);

    return(
        <div className='bg-[#262a38] px-10 sm:px-20 py-20 gap-32 flex justify-center flex-col items-center'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pricing - Competitive Rates for Quality Digital Services from Adey Digital</title>
                <meta name="description" content="Check out our Pricing page for information on the competitive rates for the high-quality digital services offered by Adey Digital. Contact us to schedule a consultation and discuss your project needs." />
                <meta
                    name="keywords"
                    content="Adey Digital, pricing, rates, digital services, consultation, project needs"
                />
            </Helmet>
            <div className='w-full flex flex-col gap-5 justify-center items-center'>
                <h1 className='text-4xl sm:text-5xl text-white capitalize'>Choose your plan</h1>
                {/*<p className='capitalize text-white font-thin text-center'>Lorem ipsum dolor sit amet consectetur. Velit tempor dolor a</p>*/}
                <div className='flex  w-full sm:w-[25%] justify-around'>
                    <p className='text-[#74767D] text-lg font-semibold'>Monthly</p>
                    <label class="inline-flex relative items-center cursor-pointer">
                        <input
                            type="checkbox"
                            className="sr-only peer"
                            checked={enabled}
                            readOnly
                        />
                        <div
                            onClick={() => {
                            setEnabled(!enabled);
                        }}
                            className="w-12 h-6 bg-[#4D5265] rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] peer-checked:after:bg-[#FFF102] after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#4D5265]"
                            ></div>

                    </label>

                    <p className='text-white text-lg'>Yearly</p>
                    <img src='/off.svg'/>
                    {/*<p className='w-20 text-center py-1 text-sm border-[1px] border-[#FFF102] rounded-md text-[#FFF102]'>20% off</p>*/}
                </div>

            </div>
            <Plans enabled = {enabled}/>

            <div className='flex flex-col items-center justify-center gap-5 w-full'>
                <h1 className='text-center text-white text-4xl'>All Included Features</h1>

                <Features />
            </div>

            <div className='w-full sm:px-10 h-auto sm:h-[70vh]'>
                <h1 className='text-center text-white text-4xl capitalize mb-5 sm:mb-0 h-[20%]'>your new team</h1>
                <div className='flex flex-col sm:flex-row gap-5 h-[80%] justify-between'>
                    <div className='w-full sm:w-[30%] flex flex-col items-center gap-5 bg-[#2C3145] p-5 sm:p-10'>
                        <h1 className='text-white text-2xl sm:text-xl'>Social Media Manager</h1>
                        <p className='text-white font-thin'>
                            Our Social Media Manager is the leader of your personalized team and the main point of contact for you. They will work closely with our Graphic Designer and Copy Writer to develop a detailed digital marketing strategy that aligns with your business goals. They will create a content calendar, produce engaging content and will be in charge of reporting to you on the progress of your campaigns, including metrics such as website traffic, social media engagement, and ad performance.
                        </p>
                    </div>
                    <div className='w-full sm:w-[30%] flex flex-col items-center gap-5 bg-[#2C3145] p-10'>
                        <h1 className='text-white text-2xl sm:text-xl'>Graphic Designer</h1>
                        <p className='text-white font-thin'>
                            Our Graphic Designer is responsible for creating visually appealing designs that will help your brand stand out. They will work closely with our Social Media Manager and Copy Writer to ensure that all of our client's visual content is on-brand, high-quality and aligns with their digital marketing strategy.
                        </p>
                    </div>
                    <div className='w-full sm:w-[30%] flex flex-col items-center gap-5 bg-[#2C3145] p-10'>
                        <h1 className='text-white text-2xl sm:text-xl'>Copy Writer</h1>
                        <p className='text-white font-thin'>
                            Our copywriter is in charge of coming up with captivating and interesting material that appeals to your target market. All of our client's written material will be on-brand, of the highest caliber, and in line with your digital marketing strategy thanks to their close collaboration with our social media manager and graphic designer. Additionally, they will guarantee that the website is SEO-optimized, increasing its likelihood of appearing higher in search engine results.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Pricing