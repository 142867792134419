import {BiRightArrowAlt} from 'react-icons/bi'
import React,{useState} from "react";
import MailChimpForm from "./MailChimpSignup";



const Footer = () => {
const [email, setEmail] = useState("");
    const [response, setResponse] = useState({
        type: "",
        message: "",
    });
const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const res = await fetch("https://adeydigital.us9.list-manage.com/subscribe/post?u=3b10ce45d5cb2ecdb4b1510b6&id=7ac58818f2&f_id=002d1fe1f0", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ EMAIL: email }),
        });

        const json = await res.json();
        setResponse({
            type: json.result === "success" ? "success" : "error",
            message: json.msg,
        });
    } catch (error) {
        setResponse({
            type: "error",
            message: error.message,
        });
    }
}
    return(
        <div className='pt-10 sm:pt-20 pb-10 px-12 sm:px-28 w-full h-auto bg-[#2C3145]'>
            <div className='flex flex-col sm:flex-row justify-between items-start gap-10 sm:gap-0'>
                <div className='w-full sm:w-[20%]'>
                    <h1 className='text-white text-2xl sm:text-lg mb-5'>Links</h1>
                    <div className='text-gray-200 text-lg sm:text-base font-thin flex flex-col gap-2'>
                        <p>Home</p>
                        <p>Services</p>
                        <p>Pricing</p>
                        <p>Blog</p>
                        <p>About</p>
                        <p>Contact</p>
                        
                    </div>
                </div>
                <div className='w-full sm:w-[20%]'>
                    <h1 className='text-white text-2xl sm:text-lg mb-5'>Information</h1>
                    <div className='text-gray-200 text-lg sm:text-base font-thin flex flex-col gap-2'>
                        <p>FAQ</p>
                        <p>Blog</p>
                        <p>Support</p>
                    </div>
                </div>
                <div className='w-full sm:w-[20%]'>
                    <h1 className='text-white text-2xl sm:text-lg mb-5'>Company</h1>
                    <div className='text-gray-200 text-lg sm:text-base font-thin flex flex-col gap-2'>
                        <p>About us</p>
                        <p>Careers</p>
                        <p>Contact us</p>
                        <p>Lift Media</p>
                    </div>
                </div>
                {/*<div className='w-full sm:w-[30%] rounded-sm p-8  bg-[#3c3f4c]'>*/}
                {/*    <form>*/}
                {/*        <h1 className='text-white font-semibold text-lg'>Subscribe</h1>*/}
                {/*        <div className='flex justify-start items-center'>*/}
                {/*        <input className='px-2 sm:px-4 py-3 outline-none rounded-tl-md rounded-bl-md  my-5' placeholder='Email Address' type="email"*/}
                {/*               value={email}*/}
                {/*               onChange={(event) => setEmail(event.target.value)}*/}
                {/*               id="email"/>*/}
                {/*        <div className='py-2 px-2 bg-[#FFF102] rounded-tr-md rounded-br-md cursor-pointer' onClick={handleSubmit}>*/}
                {/*            <BiRightArrowAlt className='' size='2rem' />*/}

                {/*        </div>*/}
                {/*        </div>*/}
                {/*        {response.message && (*/}
                {/*            <div*/}
                {/*                className={`response ${*/}
                {/*                    response.type === "success" ? "success" : "error"*/}
                {/*                }`}*/}
                {/*            >*/}
                {/*                {response.message}*/}
                {/*            </div>*/}
                {/*        )}*/}
                {/*        <p className='text-gray-300 font-thin'>Hello, we are Adey. Our goal is to translate the positive effects from revolutionizing how companies engage with their clients & their team.</p>*/}
                {/*    </form>*/}
                {/*</div>*/}
                <MailChimpForm/>
            </div>

            <div className='h-[1px] my-10 bg-gray-500'></div>

            <div className='flex justify-between items-center flex-wrap gap-5'>
                <img src='/logo.svg' className='w-28 cursor-pointer'/>
                <div className='flex w-[50%] sm:w-[20%] justify-between'>
                    <h1 className='text-white cursor-pointer'>Terms</h1>
                    <h1 className='text-white cursor-pointer'>Privacy</h1>
                    <h1 className='text-white cursor-pointer'>Cookies</h1>
                </div>
                <div className='flex w-[100%] sm:w-[10%] justify-between'>
                    <img src='/icons/Linkedin.svg'/>
                    <img src='/icons/Twitter.svg'/>
                    <img src='/icons/Facebook.svg'/>
                </div>
            </div>
        </div>
    )
}

export default Footer