    import BlogCard from "../components/BlogCard";
    import RecentPost from "../components/RecentPost";
    import { useParams } from 'react-router';
    import { useQuery, gql, useMutation } from "@apollo/client";
    import {useEffect, useState} from "react";
    import {useSubscription} from "@apollo/client";
    import ReactLoading from "react-loading";
    import {Helmet} from "react-helmet";
    import { useNavigate } from "react-router-dom";
    const { astToHtmlString } = require('@graphcms/rich-text-html-renderer')

    const PUBLISH = gql`
    mutation publishComment($id: ID!){
    
      publishComment(where: {id: $id}) {
        id
      }
    }
    
    `

    const CREATE_COMMENT = gql`
      mutation CreateComment($name: String!, $email: String!, $comment: String!, $slug: String!, $dateCommented: Date!) {
        createComment(
          data: {name: $name, email: $email, comment: $comment,dateCommented: $dateCommented, post: {connect: {slug: $slug}}}
        ) {
          id
        }
      }
    `;


    const QUERY = gql`
        query getPost($slug: String!){
            post(where: {slug: $slug}){
                title
                description
                content{
                    html
                    raw
                }
                datePublished
                slug
                author{
                    name
                    biography
                    avatar{
                        url
                    }
                }
                coverPhoto{
                    url
                }
                comments{
                    name,
                    comment,
                    dateCommented
                }
               
            }
        }
        
    `


    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth,day ].join("-");
    }


    const Blogdetail=()=>{

        const { slug } = useParams();
        const navigate = useNavigate();


        const [createComment, { commentData, commentError, commentLoading }] = useMutation(CREATE_COMMENT);
        const [publishComment, { pubData, pubError, pubLoading }] = useMutation(PUBLISH);

        const { loading, error, data } = useQuery(QUERY, {
            variables: { slug },
        });
        const [blog, setBlog] = useState({});
        const [comment, setComment] = useState('')
        const [name, setName] = useState('')
        const [email, setEmail] = useState('')


        if (loading) return <div className='flex justify-center items-center'><ReactLoading type='bubbles' color='#DDD104' height={400} width={300} /></div>
        if (error) return <pre>{error.message}</pre>



        const submitComment = () =>{



            createComment({
                variables: {
                    name: name,
                    email: email,
                    comment: comment,
                    dateCommented: convert(new Date()),
                    slug: slug,
                },
            }).then(r => {
                console.log(r.data.createComment.id)
                publishComment({
                    variables:{
                        id:r.data.createComment.id
                    }
                })
                    .then(res =>{
                        navigate(0);
                    })
                setEmail('')
                setName('')
                setComment('')
            })
                .catch(e =>{
                    console.log(e)
                })
        }

        const sharedClasses = "dark:text-white"
        const bodyClasses = "text-lg text-gray-700"

        const renderers = {
            h1: ({ children }) => `<h1 class="my-10 text-4xl text-gray-900 md:text-5xl lg:text-5xl ${sharedClasses}"> ${children} </h1>`,
            h2: ({ children }) => `<h2 class="my-8 text-3xl text-gray-900 md:text-5xl lg:text-4xl ${sharedClasses}"> ${children} </h2>`,
            h3: ({ children }) => `<h3 class="text-3xl ${sharedClasses}"> ${children} </h3>`,
            h4: ({ children }) => `<h4 class="text-2xl ${sharedClasses}"> ${children} </h3>`,
            h5: ({ children }) => `<h5 class="text-xl ${sharedClasses}"> ${children} </h3>`,
            h6: ({ children }) => `<h6 class="text-large ${sharedClasses}"> ${children} </h3>`,
            p: ({ children }) => `<p class="my-7 text-lg ${bodyClasses} ${sharedClasses}"> ${children} </p>`,
            ul: ({ children }) => `<ul class="list-disc list-inside my-4 text-lg ${bodyClasses} ${sharedClasses}"> ${children} </ul>`,
            ol: ({ children }) => `<ol class="list-decimal list-inside my-4 text-lg ${bodyClasses} ${sharedClasses}"> ${children} </ol>`,
            li: ({ children }) => `<li class="my-7 text-lg ${bodyClasses} ${sharedClasses}"> ${children} </li>`,
            code: ({ children }) => `<code class="bg-gray-100 dark:bg-gray-800 rounded-md p-2 text-sm ${sharedClasses}"> ${children} </code>`,
            code_block: ({ children }) => `<pre class="bg-gray-100 dark:bg-gray-800 overflow-y-scroll rounded-md p-2 text-sm ${sharedClasses}"> ${children} </pre>`,
        };


        function AddContent({post}) {
            // const content = post.content.json.children
            const rendered = astToHtmlString({content: post, renderers})
            // const cleanedHtml = rendered.replace(/class=/g, 'className=');
            return (
                <div dangerouslySetInnerHTML={{ __html: rendered }} />
            );
        }

        return(
            <div className='flex w-full sm:flex-row flex-col px-5 sm:px-20 justify-between gap-10 items-start h-auto'>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Adey Digital - fetch title of the blog</title>
                    <meta name="description" content={data.post.description} />
                    <meta
                        name="keywords"
                        content="fetch category tag from the blog"
                    />
                </Helmet>
                    <div className='mb-14 w-full sm:w-[65%] flex flex-col gap-16 '>
                        <div className='h-auto text-white bg-[#272d47] rounded-3xl px-10 py-8 flex flex-col gap-8 items-center text-center'>
                            <img src={data.post.coverPhoto.url} className='w-full rounded-2xl h-80 w-full'/>
                            <h1 className='text-2xl font-semibold'>{data.post.title}</h1>
                            <div className='h-[10%] flex flex-col sm:flex-row justify-center items-center w-full gap-5 sm:w-[70%] text-center'>
                                <div className='flex w-full sm:w-[50%] gap-5 items-center justify-center'>
                                    <img src={data.post.author.avatar.url} className='h-20 w-20 rounded-[50%]'/>
                                    <h1 className=' text-lg'>{data.post.author.name}</h1>
                                </div>
                                <div className='flex w-full sm:w-[50%] gap-5 items-center justify-center'>
                                    <img src='/icons/calendar.svg'/>
                                    <h1 className=' text-lg'>{data.post.datePublished}</h1>
                                </div>
                            </div>

                            <div className='text-xl w-full font-normal text-left'><AddContent post={data.post.content.raw}/></div>
                            {/*<button onClick={blockClickHandler} className='text-black bg-[#FFF102] hover:bg-[#D7CD24] px-10 rounded-3xl py-2 font-semibold text-xl'>Continue Reading</button>*/}
                        </div>

                        <div className='h-auto text-white bg-[#272d47] rounded-3xl px-10 py-8 flex flex-col gap-8 items-center text-center'>
                            <h1 className='text-white text-xl'>{data.post.author.name}</h1>
                            <p className='text-white text-lg'>“{data.post.author.biography}”</p>
                        </div>

                        <div className="bg-[url('/public/blog2.png')] w-full h-[30vh] bg-no-repeat bg-cover rounded-3xl opacity-40 flex items-center justify-center flex-col">
                            <p>Mar 8, 2022</p>
                            <p className='text-xl font-medium text-white'>Lorem Ipsum Loeiipa</p>
                        </div>

                        <div className='h-auto text-white bg-[#272d47] rounded-3xl px-10 py-8 flex flex-col items-start gap-8 '>
                            <h1 className='text-white text-2xl border-b-2 pb-3 w-full'>Leave a Comment</h1>
                            {/*<form>*/}
                                <textarea className='h-40 outline-none rounded-2xl w-full p-5 text-black' placeholder='Message' onChange={(e)=>setComment(e.target.value)}></textarea>
                                <div className='flex gap-10 text-black w-full'>
                                    <input type='text' placeholder='Name' className='px-5 py-2 w-[50%] outline-none h-12 rounded-xl' onChange={(e)=>setName(e.target.value)}/>
                                    <input type='text' placeholder='Email' className='px-5 py-2 w-[50%] outline-none h-12 rounded-xl' onChange={(e)=>setEmail(e.target.value)}/>
                                </div>
                            {/*</form>*/}
                            {/*<div className='flex gap-5'>*/}

                            {/*    <input type='checkbox'/>*/}
                            {/*    <label>Save my name, email in this browser for the next time I comment</label>*/}
                            {/*</div>*/}
                            <button onClick={submitComment} className='text-black bg-[#FFF102] hover:bg-[#D7CD24] px-8 rounded-3xl py-2 font-medium text-lg'>Post Comment</button>

                        </div>

                        <div className='h-auto text-white bg-[#272d47] rounded-3xl px-10 py-8 flex flex-col items-start gap-3'>
                            <h1 className='text-white text-2xl border-b-2 pb-3 w-full'>{data.post.comments.length} Comments</h1>
                            {
                                data.post.comments.map((e,i) =>(
                                    <div key={i}>
                                     <h3 className='text-white text-lg mt-5'>{e.name}<span className='text-gray-300 ml-2'>on {e.dateCommented}</span></h3>
                                        <p className='text-white text-lg font-thin'>{e.comment}</p>
                                    </div>
                                ))
                            }



                        </div>
                    </div>



                <div className='w-[35%]'>
                    <RecentPost />
                </div>
            </div>
        )
    }



    export default Blogdetail