import {useNavigate} from "react-router-dom";

const BlogCard =(props)=>{

    const router = useNavigate()
    const blockClickHandler = ()=>{
        console.log("mehhhhhhh")
        router(`/blogdetail/${props.slug}`)
    }

    return(
        <div className='text-white bg-[#272d47] rounded-3xl px-5 py-8 flex flex-col gap-5 items-center text-center'>
            <img src={props.image} className='w-full h-85 rounded-2xl'/>
            <h1 className='text-2xl h-[5%] font-semibold'>{props.title}</h1>
            <div className='h-[10%] flex flex-col sm:flex-row justify-center items-center w-full gap-5 sm:w-[70%] text-center'>
                <div className='flex w-full sm:w-[50%] gap-5 items-center justify-center'>
                    <img src={props.avatar} className='h-20 w-20 rounded-[50%]'/>
                    <h1 className=' text-lg'>{props.authorName}</h1>
                </div>
                <div className='flex w-full sm:w-[50%] gap-5 items-center justify-center'>
                    <img src='/icons/calendar.svg'/>
                    <h1 className=' text-lg'>{props.date}</h1>
                </div>
            </div>
            <p className=' text-xl font-normal'>{props.description}</p>
            <button onClick={blockClickHandler} className='h-[7%] text-black bg-[#FFF102] hover:bg-[#D7CD24] px-10 rounded-3xl py-2 font-semibold text-xl'>Continue Reading</button>
        </div>
    )
}

export default BlogCard