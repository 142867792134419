import React from 'react'

const Features =()=>{


    return(
        <div className='w-full sm:w-[90%] '>
            <div className='flex h-20 gap-5 sm:gap-20 justify-evenly items-center bg-[#FFF102] opacity-[65%] rounded-md px-3 sm:px-20'>
                <h1 className='w-[25%] text-lg sm:text-xl text-center'>Features</h1>
                <h1 className='w-[25%] text-lg sm:text-xl text-center'>Organic</h1>
                <h1 className='w-[25%] text-lg sm:text-xl text-center'>Booster</h1>
                <h1 className='w-[25%] text-lg sm:text-xl text-center'>Custom/Enterprise</h1>
            </div>
            <div className=''>
                <Feature/>
            </div>
        </div>
    )
}


const Feature = ()=>{

    const features = [
        {
            name: "Social Media Manager",
            organic: true,
            inorganic: true,
            custom: true
        },
        {
            name: "Graphic Designer",
            organic: true,
            inorganic: true,
            custom: true
        },
        {
            name: "Content Writer",
            organic: true,
            inorganic: true,
            custom: true
        },
        {
            name: "SEO for Website",
            organic: true,
            inorganic: true,
            custom: true
        },
        {
            name: "Content Strategy and Calendar",
            organic: true,
            inorganic: true,
            custom: true
        },
        {
            name: "Regular Reporting and Metrics",
            organic: true,
            inorganic: true,
            custom: true
        },
        {
            name: "Ongoing Support and Maintenance",
            organic: true,
            inorganic: true,
            custom: true
        },
        {
            name: "Final Meeting to Evaluate Results",
            organic: true,
            inorganic: true,
            custom: true
        },
        {
            name: "Ad Specialist",
            organic: false,
            inorganic: true,
            custom: true
        },
        {
            name: "Ad Campaign Management",
            organic: false,
            inorganic: true,
            custom: true
        },
        {
            name: "Video Production",
            organic: false,
            inorganic: false,
            custom: true
        },
        {
            name: "YouTube Channel Management",
            organic: false,
            inorganic: false,
            custom: true
        },
        {
            name: "Email Marketing",
            organic: false,
            inorganic: false,
            custom: true
        },
        {
            name: "Public Relations",
            organic: false,
            inorganic: false,
            custom: true
        },
        {
            name: "Influencer Marketing",
            organic: false,
            inorganic: false,
            custom: true
        },
        {
            name: "Event Marketing",
            organic: false,
            inorganic: false,
            custom: true
        },
        {
            name: "Mobile App Development",
            organic: false,
            inorganic: false,
            custom: true
        },
        {
            name: "E-commerce Website Development",
            organic: false,
            inorganic: false,
            custom: true
        },
        // {
        //     name: "CRM Integration",
        //     organic: false,
        //     inorganic: false,
        //     custom: true
        // },
    ]

    return(
        <div>
            {
                features.map((feature,i)=>(
                    <div >
                        <div className='flex h-28 my-5 sm:my-2 sm:h-20 justify-evenly gap-5 sm:gap-20 items-center gap-5 sm:px-20'>

                        <p className='w-[25%] text-lg sm:text-xl text-center text-white font-thin'>{feature.name}</p>
                        <p className='w-[25%] text-xl text-center text-white flex justify-center font-thin'>
                            {
                            feature.organic ? <img src="/yes.svg" className='w-8 text-white'/> : <img src="/not.svg" className='w-6 '/>
                        }
                        </p>
                        <p className='w-[25%] text-xl text-center text-white font-thin flex justify-center'>{
                            feature.inorganic ? <img src="/yes.svg" className='w-8 text-white'/> : <img src="/not.svg" className='w-6 '/>
                        }</p>
                        <p className='w-[25%] text-xl text-center text-white font-thin flex justify-center'>
                            {
                                feature.custom ?  <img src="/yes.svg" className='w-8 text-white'/> : <img src="/not.svg" className='w-6 '/>
                            }</p>
                        </div>
                        <div className='bg-gray-400 h-[1px]'></div>

                    </div>

                ))
            }
        </div>

    )
}

export default Features