import React,{useEffect, useState} from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from './pages/Home'
import About from "./pages/About";
import Header from '../src/components/Header'
import Footer from "./components/Footer";
import Services from "./pages/Services";
import Pricing from "./pages/Pricing";
import Contactus from "./pages/contactus";
import Blog from './pages/blog'
import Blogdetail from "./pages/blogdetail";

const App=() =>{
    const [scrollPosition, setScrollPosition] = useState(false)
    const handleScroll = () => {
        const position = window.pageYOffset;
        if(position >=10){
            setScrollPosition(true)
        } else {
            setScrollPosition(false)
        }


   };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <div className='bg-gradient-to-tr from-[#1E1E1F] to-[#2C3145] overflow-x-hidden sm:overflow-x-visible'>

        <BrowserRouter>
            <Header scrollPosition={scrollPosition}/>
            <Routes className=''>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/services" element={<Services/>}/>
                <Route path="/blog" element={<Blog/>}/>
                <Route path="/blogdetail/:slug" element={<Blogdetail/>}/>
                <Route path="/pricing" element={<Pricing/>}/>
                <Route path="/contactus" element={<Contactus/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    </div>
  );
}

export default App;
