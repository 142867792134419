import React from 'react'
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

import "@splidejs/react-splide/css";
const Carousel = ()=>{
    return(
        <div className='h-[10vh] shadow-2xl'>
            <Splide
                className='w-full flex h-full justify-around items-center'
                options={{
                    type: 'loop',
                    // rewind: true,
                    perPage: 7,
                    arrows: false,
                    pagination: false,
                    drag: "free",
                    gap: "0",
                    autoScroll: {
                        pauseOnHover: false,
                        pauseOnFocus: false,
                        rewind: false,
                        speed: 0.5
                    }

                }}
                extensions={{ AutoScroll }}
            >

                <SplideSlide className='flex justify-center items-center pt-3 border-red-100 '>
                    <img src='/icons/star.svg'/>
                </SplideSlide>
                    <SplideSlide className=' flex justify-center items-center pt-3 border-red-100'>
                            <h1 className='text-[1.6rem] text-[#FFF102] mx-10'>DIGITAL MARKETING</h1>
                    </SplideSlide>
               <SplideSlide className='flex justify-center items-center pt-3 border-red-100'>
                            <img src='/icons/star.svg'/>
                    </SplideSlide>
                    <SplideSlide className=' flex justify-center items-center pt-3 border-red-100'>
                            <h1 className='text-[1.6rem] text-[#FFF102] mx-10'>DEVELOPMENT</h1>
                    </SplideSlide>
                <SplideSlide className='flex justify-center items-center pt-3 border-red-100'>
                    <img src='/icons/star.svg'/>
                </SplideSlide>
                <SplideSlide className=' flex justify-center items-center pt-3 border-red-100'>
                    <h1 className='text-[1.6rem] text-[#FFF102] mx-10'>UI/UX DESIGN</h1>
                </SplideSlide>
                <SplideSlide className='flex justify-center items-center pt-3 border-red-100'>
                    <img src='/icons/star.svg'/>
                </SplideSlide>
                <SplideSlide className=' flex justify-center items-center pt-3 border-red-100'>
                    <h1 className='text-[1.6rem] text-[#FFF102] mx-10'>SOCIAL MEDIA</h1>
                </SplideSlide>



            </Splide>
        </div>
    )
}

export default Carousel