import {Helmet} from "react-helmet";

const Contactus =()=>{
    return(
        <div className='flex flex-col items-center bg-[#2c3145] h-auto py-14 px-5 sm:px-20 gap-5'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us - Get in Touch with Adey Digital</title>
                <meta name="description" content="Need more information or want to schedule a consultation? Contact Adey Digital on our Contact Us page. Our team of experts are ready to assist you with all your digital needs." />
                <meta
                    name="keywords"
                    content="Adey Digital, contact, consultation, team, experts, digital needs"
                />
            </Helmet>
            <div className='flex flex-col items-center justify-center '>
                <h1 className='text-white text-4xl font-semibold leading-relaxed'>Contact Us</h1>
                <p className='text-white text-lg font-thin w-full sm:w-[70%] text-center '>Want to get in touch with us for a partnership, paid promotion, advertising, or just to say hello? Use the form below.</p>
            </div>
            <form className='w-full sm:w-[45%]' action="https://formsubmit.co/adey.digital4@gmail.com" method="POST">
                <div className='flex flex-col gap-1 my-5'>
                    <label className='text-white text-lg'>Name</label>
                    <input className='text-white text-normal outline-none rounded-md h-12 border-[1px] border-[#848484] bg-transparent px-5 text-lg' name="name" placeholder='Sophie Moore'/>
                </div>
                <div className='flex flex-col gap-1 my-5'>
                    <label className='text-white text-lg '>Email</label>
                    <input className='text-white text-normal outline-none rounded-md h-12 border-[1px] border-[#848484] bg-transparent px-5 text-lg' name="email" placeholder='example@youremail.com'/>
                </div>
                <div className='flex flex-col gap-1 my-5'>
                    <label className='text-white text-lg'>Name</label>
                    <textarea className='text-white text-normal outline-none rounded-md h-36 border-[1px] border-[#848484] bg-transparent px-5 text-lg' name="message" placeholder='message'/>
                </div>
                <input type='submit' className='w-full bg-[#FFF102] py-3 font-semibold text-lg rounded-md hover:bg-[#DDD104]'/>
            </form>
        </div>
    )
}

export default Contactus