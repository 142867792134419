import Carousel from "../components/Home/Carousel";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import FadeIn from 'react-fade-in';
import {useEffect, useState} from "react";
import {motion} from "framer-motion";

const Home = ({howWeWork})=>{
    const router = useNavigate()

    const [mousePosition, setMousePosition] = useState({
        x: null,
        y: null
    })

    // console.log(mousePosition)

    useEffect(()=>{
        const handleMouseMove = (e) => {
            setMousePosition({
                x: e.clientX,
                y: e.clientY
            })
            // console.log(e)
        }
        window.addEventListener('mousemove', handleMouseMove)
        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    },[])

    const variants = {
        default:{
            x: mousePosition.x - 35,
            y: mousePosition.y - 32,
        }
    }

    const seeHowOnClick = ()=>{
        router('/services')
        howWeWork.current.scrollIntoView({ behavior: 'smooth' })
        console.log(howWeWork.current)
    }


    return(
        <div className='bg-transparent '>
            {/* eslint-disable-next-line react/jsx-no-undef */}

            <Helmet>
                <meta charSet="utf-8" />
                <title>Adey Digital - Your Trusted Partner for Digital Solutions</title>
                <meta name="description" content="Welcome to Adey Digital, your one-stop solution for all your digital needs. From website design and development to digital marketing, we provide comprehensive services to help your business succeed online." />
                <meta
                    name="keywords"
                    content="Adey Digital, home, website design, development, digital marketing, online, business, solutions, partner"
                />
            </Helmet>
            <div className="h-[88vh] Home">
                {/*<motion.div className='cursor' variants={variants} animate='default'></motion.div>*/}
                <div className="h-full bg-[url('/public/bg.svg')] bg-no-repeat bg-cover px-5 sm:px-20 py-20">
                    {/*<div className="relative z-10 -top-20 right-0 w-full sm:w-[70%] bg-[url('/public/map-bg.svg')] bg-no-repeat h-[100%] ml-auto mb-"></div>*/}
                    <FadeIn transitionDuration={1000} delay={200}>

                    <div className='w-full sm:w-[50%] h-[40%]'>
                        <h1 className='text-5xl sm:text-6xl leading-relaxed sm:leading-tight text-white uppercase font-medium'>Bringing <span className='text-[#FFF102]'>Brands</span> <br/>
                            to life with strategy
                            and design</h1>
                        <button onClick={seeHowOnClick} className='bg-[#D9D9D9] w-[70%] sm:w-[30%] py-2 px-5 h-[24%] flex justify-around items-center gap-2 mt-10 rounded-2xl text-[1.4rem] font-bold text-right'>
                            <img src='/icons/play.svg' className='w-[20%]'/>
                            See How
                        </button>
                    </div>
                    </FadeIn>
                </div>
            </div>
            <Carousel/>
            <div className='h-auto w-full px-8 sm:px-20 py-20 sm:py-32 flex flex-col gap-32'>
                <div className='sm:h-[95vh] flex flex-col sm:flex-row justify-between items-center gap-10'>
                    <div className='text-2xl w-full sm:w-auto text-white flex justify-center items-start bg-[#32374e] rounded-2xl'>
                        <img src='/home-service.svg' className='h-[60%] w-[90%] z-10'/>
                    </div>
                    <div className='h-full w-full sm:w-[55%] sm:px-5'>
                        <h1 className='capitalize text-white text-4xl leading-tight'>We provide <span className='text-[#FFF102]'>different services</span>  & <span className='text-[#FFF102]'>features    </span> for your bussiness</h1>
                        <img src='/line.svg' className='my-5'/>
                        <p className='text-[#BBBBBB] font-thin text-lg capitalize'>We offer a wide range of services, so whether you are just starting
                            out or have been in the business for years, we have a package for you.
                        </p>
                        <div className='mt-5 h-[70%] text-white flex justify-between items-center sm:flex-row flex-wrap flex-col gap-5 sm:gap-2'>
                            <div className='w-full sm:w-[45%] border-2 h-[40%] rounded-md p-5'>
                                <p> Professional website design and development services to help your business establish a strong online presence. with responsive design tailored to your specific needs and goals.</p>
                            </div>
                            <div className='w-full sm:w-[45%] border-2 h-[40%] rounded-md p-5'>
                                <p>Digital marketing services that are designed to help your business reach new customers and increase conversions. such as SEO, PPC, social media marketing, and email marketing.</p>
                            </div>
                            <div className='w-full sm:w-[45%] border-2 h-[40%] rounded-md p-5'>
                                <p>E-commerce solutions  businesses with the tools they need to successfully sell products or services online. setting you up with online store, integrate with payment gateways.</p>
                            </div>
                            <div className='w-full sm:w-[45%] border-2 h-[40%] rounded-md p-5'>
                                <p>Branding and graphic design services to help your business build a strong and consistent brand image.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full flex justify-center flex-col items-center'>
                    <h1 className='text-4xl text-white text-center capitalize'>why you need a digital marketing agency</h1>
                    <img src='/line-2.svg' className='mt-10'/>
                    <div className='w-full mt-10 flex sm:flex-row flex-col-reverse flex-wrap gap-10 items-center justify-around'>
                        <div className='w-full sm:w-[45%]'>
                            <h1 className='capitalize text-2xl text-white font-semibold'>your customers are on social media</h1>
                            <p className='text-gray-300 text-lg my-5 capitalize'>Are you struggling to reach your customers on social media? You're not alone. With over 4.59 billion people using social media worldwide, it's more important than ever for businesses to have a strong presence on these platforms. But simply having a social media account isn't enough – you need to be able to effectively connect with your target audience.</p>
                            <p className='text-gray-300 text-lg my-5 capitalize'>That's where digital marketing comes in. By utilizing tactics such as social media advertising and engagement, we can help you reach your customers where they are and turn those online leads into paying customers. And with our data-driven approach, we can track the success of our campaigns and continually optimize for even better results.</p>
                            <p className='text-gray-300 text-lg my-5 capitalize'>Don't miss out on this opportunity to connect with your customers – let us help you succeed on social media."</p>

                        </div>
                        <div className='w-full sm:w-[45%] py-6 bg-[#32374e] rounded-2xl flex justify-center items-center'>
                            <img src='/home-digital.svg'/>
                        </div>
                    </div>

                    <div className='w-full mt-10 flex sm:flex-row flex-col flex-wrap gap-10 items-center justify-between'>
                        <div className='w-full sm:w-[45%] py-6 bg-[#32374e] rounded-2xl flex justify-center items-center'>
                            <img src='/home-digital2.svg'/>
                        </div>
                        <div className='w-full sm:w-[45%]'>
                            <h1 className='capitalize text-2xl text-white font-semibold'>You want to stand out from the competition on social media.</h1>
                            <p className='text-gray-300 text-lg my-5 capitalize'>Are you sick and weary of stumbling around in the social media content void? It can be challenging to differentiate yourself from the competition, particularly when everyone is competing for attention. However, by using the proper strategy, you may cut through the clutter and leave a lasting impact on your target audience.</p>
                            <p className='text-gray-300 text-lg my-5 capitalize'>Our digital marketing firm steps in to help with that. We can assist you in reaching your target audience and standing out from the competition by using strategies like social media advertising and interaction. Together, we'll create a special plan that speaks to your target market and highlights the distinctive qualities of your company. We'll make sure you stand out on social media so your rivals can't steal the show.</p>
                            {/*<p className='text-gray-300 text-lg my-5'>Don't miss out on this opportunity to connect with your customers – let us help you succeed on social media."</p>*/}

                        </div>

                    </div>
                </div>



                <div className='mt-10'>
                    <h1 className='text-center font-semibold text-4xl text-white'>Our Process</h1>
                    <div className='flex mt-10 sm:flex-row flex-col'>
                        <div className='w-full sm:w-[50%] py-10 px-5 bg-[#252731] capitalize leading-tight'>
                            <h1 className='text-center text-white text-2xl capitalize'>A journey on how we help you grow</h1>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>1. Initial consultation:</p>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>2. Proposal and contract:</p>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>3. Kickoff meeting:</p>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>4. Team formation and strategy development: </p>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>5. Implementation and execution</p>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>6. Reporting and Optimization: </p>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>7. We will continually monitor and analyze the results of our efforts, and make adjustments as needed to ensure that we are meeting or exceeding your expectations.  </p>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>8. Ongoing support and maintenance: </p>
                            <p className='text-gray-300 text-lg my-4 font-thin leading-snug'>9. End of contract: We will have a final meeting with you to evaluate the results of our work together and discuss any future plans you have. </p>
                        </div>
                        <div className='bg-[#8a8a8a] w-full h-[40vh] sm:h-auto sm:w-[50%]'>
                            <iframe className='w-full h-full' src="https://www.youtube.com/embed/X2qHMfEaclY"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </div>

                <div className='flex justify-between my-10'>
                    <img src='patt.svg'/>
                    <img src='patt2.svg'/>
                </div>


                <div className='text-center flex justify-center gap-6 flex-col items-center'>
                    <h1 className='text-3xl text-white font-medium'>ARE YOU <span className='text-[#FFF102]'>READY</span>?</h1>
                    <p className='text-white text-xl w-full sm:w-[80%]'>We've helped many professionals like yourself during their digital transformation process with our 30-minute free consultations. Contact us now and let's get started!</p>
                    <a href='/contactus' className='capitalize bg-[#d9d9d9] px-4 py-1 font-bold rounded-2xl'>get a Free Quote</a>
                </div>

                <img src='patt.svg' className='ml-auto'/>

            </div>
        </div>


    )
}

export default Home
